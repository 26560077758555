<template>
  <div class="c-func-input-wrap">
    <div v-if="title" class="c-func-input-title">
      <texts :text="title" size="small" color="gray" />
    </div>
    <div v-if="!isGray" class="c-func-input">
      <template>
        <icon
          v-if="isSearch"
          class="c-func-input-icon"
          iconName="search"
          size="small"
        />
        <input
          v-if="number || type === 'number'"
          ref="editBox"
          class="c-func-input-inner"
          :class="{
            search: isSearch,
            required: required && value == null,
            'c-func-input-inner-error': error
          }"
          type="number"
          :value="value"
          :placeholder="placeholder"
          :max="max"
          :min="forceStep ? null : min"
          :step="step"
          :required="required"
          @input="updateValue($event)"
          @change="updateValueChange($event)"
          @blur="$emit('blur')"
          @focus="$emit('focus')"
        >
        <input
          v-else
          ref="editBox"
          class="c-func-input-inner"
          :value="value"
          :class="{
            search: isSearch,
            required: required && value == null,
            'c-func-input-inner-error': error
          }"
          :placeholder="placeholder"
          :required="required"
          @input="updateValue($event)"
          @change="updateValueChange($event)"
          @blur="$emit('blur')"
          @focus="$emit('focus')"
        >
      </template>
      <div class="c-func-underline" />
    </div>
    <div v-else class="c-func-input">
      <icon
        v-if="isSearch"
        class="c-func-input-icon"
        iconName="search"
        size="small"
      />
      <template v-if="!isTextArea">
        <input
          v-if="number || type === 'number'"
          ref="editBox"
          type="number"
          :max="max"
          :min="forceStep ? null : min"
          :step="step"
          class="c-func-input-inner-gray"
          :class="{
            search: isSearch,
            required: required && value == null,
            'c-func-input-inner-error': error
          }"
          :placeholder="placeholder"
          :value="value"
          :required="required"
          @input="updateValue($event)"
          @change="updateValueChange($event)"
          @blur="$emit('blur')"
          @focus="$emit('focus')"
        >
        <input
          v-else
          class="c-func-input-inner-gray"
          :type="type"
          :class="{
            search: isSearch,
            required: required && value == null,
            'c-func-input-inner-error': error
          }"
          :placeholder="placeholder"
          :value="value"
          :required="required"
          @input="updateValue($event)"
          @change="updateValueChange($event)"
          @blur="$emit('blur')"
          @focus="$emit('focus')"
        >
      </template>
      <textarea
        v-else
        class="c-func-input-textarea"
        :class="{
          search: isSearch,
          required: required && value == null
        }"
        :placeholder="placeholder"
        :value="value"
        :rows="rows"
        :required="required"
        @input="updateValue($event)"
        @change="updateValueChange($event)"
        @blur="$emit('blur')"
        @focus="$emit('focus')"
      />
    </div>
  </div>
</template>

<script>
import icon from '@/components/atoms/icon.vue'

export default {
  components: {
    icon
  },
  props: {
    /** 入力のタイプをナンバー型に変える */
    number: Boolean,
    /** プレースホルダー */
    placeholder: String,
    /** 検索ボックスかどうか */
    isSearch: Boolean,
    /** テキストエリアかどうか */
    isTextArea: Boolean,
    /** インプットの上に表示するテキスト */
    title: String,
    /** 親に戻す値 親側は:value.syncまたはv-modelで受け取る */
    value: [String, Number],
    /** Numberなどインプットボックスの型 https://developer.mozilla.org/ja/docs/Web/HTML/Element/input */
    type: String,
    /** Number型の時に選択できる値の最大値 */
    max: {
      type: Number,
      default: null
    },
    /** Number型の時に選択できる値の最小値 */
    min: {
      type: Number,
      default: null
    },
    /** Numberで使用するStep */
    step: Number,
    /** textareaの場合に行数を指定するデフォルトは６ */
    rows: {
      type: Number,
      default: 6
    },
    /** グレー表示に変更 */
    isGray: {
      type: Boolean,
      default: false
    },
    /** 必須項目（これだけでは機能的にはは必須になりません。色が変わるだけです） */
    required: {
      type: Boolean,
      default: false
    },
    /** minを参照せずにstepの値を使用する これを適用するとminの値が入らなくなるので、親でmin以下が入った場合のバリデーションを行うようにする */
    forceStep: {
      type: Boolean,
      default: false
    },
    /** エラーが発生している場合 */
    error: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    /** inputをemitする関数 */
    updateValue(e) {
      if (this.type === 'number' || this.number) {
        this.$emit('input', e.target.valueAsNumber)
      } else {
        this.$emit('input', e.target.value)
      }
    },
    /** changeの時だけ受け取る場合があるので、changeだけをemitする関数 */
    updateValueChange(e) {
      if (this.type === 'number' || this.number) {
        this.$emit('change', e.target.valueAsNumber)
      } else {
        this.$emit('change', e.target.value)
      }
    },
    /** 上部レイヤーから値を直接変更する */
    updateValueByParent(val) {
      this.$refs.editBox.value = val
    }
  }
}
</script>

<style lang="scss" scoped>
.c-func-input {
  position: relative;
  height: 100%;
  &-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &-icon {
    position: absolute;
    top: 50%;
    left: $space-sub;
    transform: translateY(-50%);
  }
  &-inner {
    display: block;
    width: 100%;
    height: 100%;
    min-height: adjustVH(32);
    padding: $space-base 0;
    border-bottom: $border-title-gray;
    font-size: $text-base;
    @include formHover;
    &.search {
      padding: $space-base $space-small $space-base $space-small * 3;
    }
    &-error {
      border-color: $red;
      box-shadow: $border-radius-caution inset, $box-shadow-hover !important;
    }
    &-gray {
      display: block;
      width: 100%;
      height: 100%;
      min-height: adjustVH(48);
      padding: $space-sub;
      background: $background-sub;
      font-size: $text-base;
      border-radius: adjustVW(8);
      transition: box-shadow $transition-base;
      &.search {
        padding: $space-base $space-small $space-base $space-small * 3;
      }
      &:focus {
        background: $background;
        box-shadow: $border-radius-emphasis inset, $box-shadow-hover;
      }
      &:out-of-range {
        box-shadow: $border-radius-caution inset, $box-shadow-hover;
      }
      &.required {
        box-shadow: $border-radius-caution inset, $box-shadow-hover;
      }
      &::placeholder {
        color: $border-gray;
      }
    }
  }
  &-textarea {
    display: block;
    width: 100%;
    height: 100%;
    min-height: adjustVH(120);
    padding: $space-sub;
    background: $background-sub;
    font-size: $text-base;
    border-radius: adjustVW(8);
    transition: box-shadow $transition-base;
    @include scrollbar;
    &:focus {
      background: $background;
      box-shadow: $border-radius-emphasis inset, $box-shadow-hover;
    }
  }
  &-title {
    margin: 0 0 $space-base;
  }
}
</style>
